.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --primary-color: #482979;
  --primary-pale-color: #4f3777;
  --primary-blur-color: #48297980;
  --primary-color-rgba: rgb(238, 77, 45);
  --white-color: #fff;
  --black-color: #0000;
  --text-color: #333;
  --border-color: #dbdbdb;
  --star-gold-color: #ffce3e;
  --header-height: 550px;
  --navbar-height: 34px;
  --navbar-with-search-height: calc(var(--header-height) - var(--navbar-height));
}

* {
  box-sizing: inherit; /* kế thừa từ cha */
}

html {
  /*--- HACK ---*/
  font-size: 62.5%; /* = 10px */
  line-height: 1.6rem;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box; /* kế thừa viền */
}

/*-- Resopensen -- */

button {
  box-shadow: 1px 1px #666;
  min-width: 80px;
}

button:hover {
  box-shadow: 2px 2px #666;
}

.grid {
  width: 1300px;
  max-width: 100%;
  margin: 0 auto;
}

.grid_row {
  display: flex;
  margin-left: -8px;
  margin-right: -8px;
}

.grid_colum-2 {
  padding-left: 8px;
  padding-right: 8px;
  width: 16.6667%;
}

.grid_colum-2_5 {
  padding-left: 8px;
  padding-right: 8px;
  width: 20.8333%;
}

.grid_colum-3 {
  padding-left: 8px;
  padding-right: 8px;
  width: 25%;
}

.grid_colum-4 {
  padding-left: 8px;
  padding-right: 8px;
  width: 33.3333%;
}

.grid_colum-7 {
  padding-left: 8px;
  padding-right: 8px;
  width: 58.333%;
}

.grid_colum-10 {
  padding-left: 8px;
  padding-right: 8px;
  width: 83.3333%;
}

.item--bold {
  font-weight: 600 !important;
}

.op-hidden {
  display: none !important;
}

.op-block {
  display: block;
}
.op-opacity0_3 {
  opacity: 0.3;
}

.op-redcolor {
  color: red;
}

.op-yellowcolor {
  color: yellow;
}

.op-primarycolor {
  color: #482979;
}
.op-backcolor {
  color: black !important;
}

.op-fontweight600 {
  font-weight: 600;
}

.op-header100 {
  height: 100%;
}
.text-prime {
  color: var(--primary-color) !important;
}

.bg_yellow {
  background-color: rgba(255, 255, 0, 0.7) !important;
}

.bg_Regent_St_Blue {   /* could color -  */
  background-color: #92cddc !important;
}

.bg_unset {
  background-color: unset !important;
}

.bg_prime_color {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.bg_prime_color2 {
  background-color: #ad7ae2 !important;
  color: white !important;
}

.bg_mate_color {
  background-color: #c7c4bd !important;
}

.bg_pink_sakura_color {
  background-color: #e6b8b7 !important;
}

.bg_orange_color {
  background-color: #fac090 !important;
}

.bg_ocean_color {
  background-color: #00b0f0 !important;
}

/* Cay sung */
.bg_sycamore_color {
  background-color: #76933c !important;
}

.bg_orange_color {
  background-color: #fda359 !important;
}

.bg_orange_blur_color {
  background-color: rgb(253, 163, 89, 0.8) !important;
}

.bg_cloud_color {
  background-color: #dbeef4 !important;
}

.bg_green_leaves_color {
  background-color: #ebf1de !important;
}
.bg_ocean_blur_color {
  background-color: rgb(119, 209, 236) !important;
}

.bg_green_color {
  background-color: rgb(130, 233, 130) !important;
}

.bg_green_blur_color {
  background-color: rgba(130, 233, 130, 0.9) !important;
}

.bg_prime_blur_color {
  background-color: var(--primary-blur-color) !important;
}

.bg_pink_color {
  background-color: #f06cc6 !important;
}

.bg_pink_lotus_color {
  background-color: #ff99cc !important;
}

.on-top {
  z-index: 11;
}

.op-text-black {
  color: black !important;
}

.op-text-left {
  text-align: left !important;
}

.op-text-red {
  color: red !important;
}

.op-text-black {
  color: black !important;
}

.op-text-ocean {
  color: #00b0f0 !important;
}
.op-text-right {
  text-align: right !important;
}

.font-600 {
  font-weight: 600 !important;
}

.font-800 {
  font-weight: 800 !important;
}

.font-size-1_5rem {
  font-size: 1.5rem !important;
}

.font-size-2rem {
  font-size: 2rem !important;
}

.range-mb-26 {
  margin-bottom: 26px;
}

.hidden-border {
  border: unset !important;
}

.max-with100 {
  max-width: 100px !important;
}

.no-padding {
  padding: 0 !important;
  border: unset !important;
  margin: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.margin-auto {
  margin: auto;
}

tr td {
  padding: 2px;
}
