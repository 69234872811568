  .modal-show-voucher .form-container {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 450px;
  }

.modal-show-voucher  .form-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }

.modal-show-voucher  .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

.modal-show-voucher  .form-group label {
    width: 30%; /* Đặt độ rộng cố định cho nhãn */
    font-weight: bold;
  }

.modal-show-voucher  .form-group input,
.modal-show-voucher  .form-group select,
.modal-show-voucher  .form-group textarea {
    width: 70%; /* Đặt độ rộng cho trường nhập liệu */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }

.modal-show-voucher  .form-group input[type="checkbox"] {
    width: auto;
    margin-left: 5px;
  }

.modal-show-voucher  .form-group .sumit-button {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }

.modal-show-voucher  .form-group .sumit-button:hover {
    background-color: #45a049;
  }